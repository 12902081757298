<template>
  <transition name="fade">
    <div class="illustration-item-page main" :key="$route.params.item_id">
      <ItemTemplate
        :data="illustration"
        category="illustration"
        :maxItemID="maxItemID"
      ></ItemTemplate>
      <div class="ss-container">
        <div class="hr"></div>
      </div>
      <div class="bg-black black-section">
        <ListTemplate
          :data="bgBlackOtherProjectsSmall"
          category="illustration"
        ></ListTemplate>
        <ListTemplate
          :data="bgBlackOtherProjectsLarge"
          category="illustration"
        ></ListTemplate>
      </div>
      <div class="bg-white white-section">
        <ListTemplate
          :data="bgWhiteOtherProjectsSmall"
          category="illustration"
        ></ListTemplate>
        <ListTemplate
          :data="bgWhiteOtherProjectsLarge"
          category="illustration"
        ></ListTemplate>
      </div>
    </div>
  </transition>
</template>

<script>
import ItemTemplate from "@/components/ItemTemplate.vue";
import ListTemplate from "@/components/ListTemplate.vue";
import Illustrations from "@/data/illustrations.js";
export default {
  name: "DiaryItem",
  components: {
    ItemTemplate,
    ListTemplate
  },
  data() {
    return {
      illustrations: Illustrations
    };
  },
  watch: {
    illustration: function() {
      if (this.illustration.length === 0) {
        this.$router.push({ name: "Error404" });
      }
    }
  },
  mounted() {
    if (this.illustration.length == 0) {
      this.$router.push({ name: "Error404" });
    }
  },
  computed: {
    illustration() {
      var _this = this;
      return this.illustrations.filter(function(item) {
        return item.id === parseInt(_this.$route.params.item_id);
      });
    },
    maxItemID() {
      return Math.max(
        ...this.illustrations.map(function(item) {
          return item.id;
        })
      );
    },
    bgBlackOtherProjectsSmall() {
      let otherProjectsSet = new Set();
      var _this = this;
      // 如果此項目為小張黑底的圖
      if (this.illustration.type === 1 && this.illustration.size === 1) {
        if (
          // 如果小張黑底的圖大於11張則隨機挑十張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 1 && item.type === 1;
          }).length > 11
        ) {
          while (otherProjectsSet.size < 10) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 1 &&
                    item.type === 1 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 1 &&
              item.type === 1 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      } else {
        if (
          // 如果小張黑底的圖大於10張則隨機挑十張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 1 && item.type === 1;
          }).length > 10
        ) {
          while (otherProjectsSet.size < 10) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 1 &&
                    item.type === 1 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 1 &&
              item.type === 1 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      }
    },
    bgBlackOtherProjectsLarge() {
      let otherProjectsSet = new Set();
      var _this = this;
      // 如果此項目為大張黑底的圖
      if (this.illustration.type === 1 && this.illustration.size === 2) {
        if (
          // 如果大張黑底的圖大於6張則隨機挑5張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 2 && item.type === 1;
          }).length > 6
        ) {
          while (otherProjectsSet.size < 5) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 2 &&
                    item.type === 1 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 2 &&
              item.type === 1 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      } else {
        if (
          // 如果大張黑底的圖大於5張則隨機挑5張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 2 && item.type === 1;
          }).length > 5
        ) {
          while (otherProjectsSet.size < 5) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 2 &&
                    item.type === 1 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 2 &&
              item.type === 1 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      }
    },
    bgWhiteOtherProjectsSmall() {
      let otherProjectsSet = new Set();
      var _this = this;
      // 如果此項目為小張白底的圖
      if (this.illustration.type === 2 && this.illustration.size === 1) {
        if (
          // 如果小張白底的圖大於6張則隨機挑5張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 1 && item.type === 2;
          }).length > 6
        ) {
          while (otherProjectsSet.size < 5) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 1 &&
                    item.type === 2 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 1 &&
              item.type === 2 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      } else {
        if (
          // 如果小張白底的圖大於5張則隨機挑5張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 1 && item.type === 2;
          }).length > 5
        ) {
          while (otherProjectsSet.size < 5) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 1 &&
                    item.type === 2 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 1 &&
              item.type === 2 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      }
    },
    bgWhiteOtherProjectsLarge() {
      let otherProjectsSet = new Set();
      var _this = this;
      // 如果此項目為大張黑底的圖
      if (this.illustration.type === 2 && this.illustration.size === 2) {
        if (
          // 如果大張黑底的圖大於6張則隨機挑5張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 2 && item.type === 2;
          }).length > 6
        ) {
          while (otherProjectsSet.size < 5) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 2 &&
                    item.type === 2 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 2 &&
              item.type === 2 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      } else {
        if (
          // 如果大張黑底的圖大於5張則隨機挑5張（排除自己）
          this.illustrations.filter(function(item) {
            return item.size === 2 && item.type === 2;
          }).length > 5
        ) {
          while (otherProjectsSet.size < 5) {
            otherProjectsSet.add(
              this.getRandomItem(
                this.illustrations.filter(function(item) {
                  return (
                    item.size === 2 &&
                    item.type === 2 &&
                    item.id !== parseInt(_this.$route.params.item_id)
                  );
                })
              )
            );
          }
          return [...otherProjectsSet];
        } else {
          // 如果數量不夠的話就把自己以外的全放出來
          return this.illustrations.filter(function(item) {
            return (
              item.size === 2 &&
              item.type === 2 &&
              item.id !== parseInt(_this.$route.params.item_id)
            );
          });
        }
      }
    }
  },
  methods: {
    getRandomItem(arr) {
      // get random index value
      const randomIndex = Math.floor(Math.random() * arr.length);
      // get random item
      const item = arr[randomIndex];
      return item;
    }
  }
};
</script>

<style lang="sass">
.illustration-item-page
  padding-top: 106px
  background-color: #111
  +deviceWidth()
    padding-top: 50px
  .item-image
    max-width: 600px
  .item-content
    h4, p
      color: #fafafa
  .black-section
    padding-bottom: 48px
    +deviceWidth()
      padding-bottom: 30px
  .white-section
    padding-top: 140px
    padding-bottom: 48px
    +deviceWidth()
      padding-top: 50px
      padding-bottom: 30px
</style>
